.App {
  /* text-align: center; */
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /* border: 10px solid green; */
  font-family: 'Signika Negative', sans-serif;
  
}
.container {
  background-color: #F3F3F3;  
  height: 90vh ; /* Subtract the height of the navigation bar from the container's height */
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* border: 10px solid lightcyan; */
  box-sizing: border-box;
}



.app-body {
  /* border: 5px solid yellow; */
  height: 100% ;
  width: 100%;
  box-sizing: border-box;
  /* padding: 150px 200px 100px 200px; */
  padding: 10.5vw 14vw 7vw 14vw;
  display: flex;
  /* flex-direction: column; */
}
.body-left {
  /* border: 1px solid blue; */
  /* display: inline-block; */
  /* flex-direction: column; */
  width: 50%;
  height: 100%;
  /* float: left; */
}

.home-img-align{
  /* float: right; */
  /* margin-left: 10px; */
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: right;
  /* padding-right: 10px; */
  /* margin-right: 10%; */
  /* border: 10px solid lightcoral; */
}

.home-img {
  /* width: 75vw; */
  height: 30vw;
  /* border: 1px solid purple; */
  border-radius: 50%;
  overflow:hidden;
  margin-right: 10px; 

  display: flex;
  justify-content: center;
  /* transform: scale(); */
   /* align-items: center; */

  /* float: right; */
  /* border: 1px solid purple; */

}

.my-img {
  /* position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; */
  object-fit: cover;
  filter: grayscale(100%);
  transform: scale(1.2);
  margin: 0px;
}

.body-right {
  width: 50%;
  height: 100%;
  justify-content: left;
  /* border: 1px solid red; */
  /* float: right; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: center; */
}

.right-body-align {
  /* border: 10px solid lavender; */
  box-sizing: border-box;
  margin-left: 10px;
  /* width: 30%;
    height: 50%; */
  /* width: 30%; */
  /* height: auto; */
  /* position: absolute; */
  /* top: 50%; */
  /* right:50%; */
  /* -ms-transform: translateY(-50%); 
  transform: translateY(-50%); */
}

.home-text {
  /* border: 1px solid pink; */
  margin-bottom: 20px;
}

.home-1{
  /* font-size: 100px;
  font-weight: 600; */
  font-size: 6.5vw;
  font-weight: 600;
}

.home-2 {
  /* font-size: 23px;
  font-weight: 600; */
  font-size: 1.5vw;
  font-weight: 600;
}

.home-3 {
  /* font-size: 17px; */
  font-size: 1.1vw;
  font-weight: lighter;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  /* padding-right: 50px; */
  /* border: 1px solid blue; */
  /* margin: 0;
  padding: 0; */
}
.home-text-align{
  margin-bottom: 0;
  padding-bottom: 5px;
}
.home-text-aligns{
  margin: 0;
  padding: 0;
}

.home-buttons-align {
  /* height: 100%; */
  /* border: 1px solid yellow; */
  width: 40vw;
}

.home-buttons {
  border-radius: 50%;
  /* height: 150px;
  width: 150px; */
  height: 10.5vw;
  width: 10.5vw;
  margin-right: 1vw;
  /* border: 1px solid black; */

  font-size: 1.5vw;
  font-weight: 600;
  
}

.home-buttons span:last-child{
  border: 0;
  margin-right: 0;
}

#yellow-btn {
  background-color: #EEA204;
  border: 1px solid #593E03;
}

#yellow-btn:hover {
  background-color: #F3F3F3;
  border: 1px solid black;
}

#red-btn {
  background-color: #FF3C24;
  border: 1px solid #63180F;
}

#red-btn:hover {
  background-color: #F3F3F3;
  border: 1px solid black;
}

#blue-btn {
  background-color: #81D8DA;
  border: 1px solid #367C7B;
}

#blue-btn:hover {
  background-color: #F3F3F3;
  border: 1px solid black;
}