@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;500;600;700&display=swap');

.navigation-bar {
  /* background-color: #333;  */
  background-color: #F3F3F3;  
  height: 60px; /* Set the height of the navigation bar */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 20px 40px;
  color: black;
  font-family: 'Signika Negative', sans-serif;
  /* display: inline-block; */
  /* border: 1px solid black; */
  /* margin: 1rem;
  padding: 2rem 2rem; */
  text-align: center;

}

.left-nav{
  display: inline-block;
  /* border: 1px solid red; */
}

.tiny-circle{
    background: #EEA204;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    float: left;
    padding-left: 1px;
    margin-right: 10px;
}

.myName {
  font-weight: bold;
  font-size: 23px;
  text-decoration: none;
}

.myJob {
  font-weight: lighter;
  font-size: 18px;
}

.right-nav{
  display: inline-block;
  /* border: 1px solid red; */
  padding: 1rem 1rem;
  font-size: 18px;
  font-weight: lighter;
  /* vertical-align: middle;  */
}
.nav-links{
  border-right: 1px solid black;
  padding-right: 5px;
  padding-left: 5px;
}

.right-nav span:last-child{
  border: 0;
  padding-right: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
