@media (max-width: 768px) {
    .Project-App {
        /* text-align: center; */
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        font-family: 'Signika Negative', sans-serif;
        /* border: 10px solid green; */
        /* box-sizing: border-box; */
      }
      .Project-Container {
        background-color: #F3F3F3;  
        height: auto ; /* Subtract the height of the navigation bar from the container's height */
        width: 100vw;
        display: flex;
        flex-direction: column;
        /* border: 10px solid darkblue; */
        box-sizing: border-box;
      }
      
    .Project-Align {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 10px 15px 80px 15px;
        display: flex;
    
        /* border: 10px solid greenyellow; */
    }
    
    .Project-Title{
      font-size: 40px;
      font-weight: bold;
      /* border: 1px solid black; */
      /* margin: 0; */
    }
    
    .Project-Table {
      /* background-color: yellow; */
      border-collapse: collapse;
      font-size: 16px;
      width: 100%;
      /* display: flex; */
      /* border: 10px solid blueviolet; */
    }
    
    .proj-left {
      width: 100%;
      /* display: table-cell; */
      /* flex: 1; */
      /* flex-wrap: wrap;
      word-break: break-word; */
      /* box-sizing: border-box; */
      /* border: 10px solid red; */
      /* display: flex; */
      display: block;

      vertical-align: top;
    
    }
    .proj-name {
      text-align: left;
      font-size: 30px;
      justify-content: left;
    }
    
    .proj-info {
      width: 100%;
      /* justify-content: left; */
      /* text-align: left; */
      /* flex-wrap: wrap; */
      word-break: break-word;
      /* font-size: 17px; */
      /* font-weight: lighter; */
      /* width: 70%; */
      display: flex;
      flex-wrap: wrap;
      /* border: 10px solid purple; */
    }
    
    .empty-row {
      padding:50px;
      /* border: 1px solid black; */
    }
    
    
    .proj-right {
      width: 100%;
      padding-top: 30px;
      /* display: table-cell; */
      /* border: 10px solid blue; */
      overflow: hidden;
      /* display: flex;
      justify-content: center;
      align-items: center; */
      /* flex:1; */
      /* display: block; */
      /* box-sizing: border-box; */
      justify-content: center;
      align-items: center;
    }
    
    .proj-img {
      width: 90%;
      height: 350px;
      /* border: 10px solid lime; */
      position: relative;
    
    }
    
    .icon-imgs{
      /* width: 400px;
      height: 400px; */
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
}