@media (max-width: 768px) {
    .App {
        /* text-align: center; */
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;

        /* border: 10px solid green; */
        font-family: 'Signika Negative', sans-serif;
        /* display: block; */
        flex-direction: column;
        
      }
      .container {
        background-color: #F3F3F3;  
        height: fit-content ; 
        width: 100vw;
        /* display: flex;
        flex-wrap: wrap; */
        /* flex-direction: column; */
        /* border: 10px solid lightcyan; */
        /* box-sizing: border-box; */
        
      }
      
      
      
      .app-body {
        /* border: 5px solid yellow; */
        height: 100% ;
        width: 100%;
        margin: 0;
        padding: 10px 15px 80px 15px;
        
        /* padding: 0; */
        /* box-sizing: border-box; */
        /* padding: 150px 200px 100px 200px; */
        display: flex;
        flex-direction: column;
      }
      .body-left {
        /* border: 1px solid blue; */
        width: 100%;
        padding-top: 50px;
        /* height: 100%; */
        /* height: !important 10%; */
        order:2;
        /* display: inline-block; */
        /* flex-direction: column; */
        /* width: 50%;
        height: 100%; */
        /* float: center; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
      }
      
      .home-img-align{
        /* float: right; */
        /* margin-left: 10px; */
        width: 100%;
        /* height: 0; */
        /* height: min-content; */
        /* position: relative; */
        display: flex;
        /* flex-direction: column; */
        /* align-items: center; */
        justify-content: center; 
        /* padding-right: 10px; */
        /* margin-right: 10%; */
        /* border: 5px solid lightcoral; */
      }
      
      .home-img {
        /* /* width: 10%; */
        /* height: 15%; */
        /* position: relative; */
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        
        /* border: 5px solid purple; */
        /* max-height: 400px; */
        border-radius: 50%;
        overflow:hidden;
        /* margin-right: 10px;  */
      position: relative;
        display: flex;
         justify-content: center;
         align-items: center;
         /* transform: scale(.65); */
      
        /* float: right; */
        /* border: 10px solid purple; */
        /* content: ""; */
        /* display: block; */
      
      }

      .my-img{
        /* position: absolute; */
        position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
        object-fit: cover;
        filter: grayscale(100%);
        transform: scale(1);
        margin: 0px;
      }
      
      .body-right {
        width: 100%;
        height: fit-content;
        order:1;

        /* display: flex;
        justify-content: center; */
        /* text-align: center; */
        /* height: 100%; */
        /* justify-content: left; */
        /* border: 1px solid red; */
        /* float: right; */
        /* display: flex;
        flex-wrap: wrap;
        justify-content: space-between; */
        /* align-items: center; */
      }
      
      .right-body-align {
        /* border: 10px solid lavender; */
        box-sizing: border-box;
        /* padding: 0px 15px 0px 0px; */
        /* display: block; */
        /* margin-left: 10px; */
        width: 100%;
        /* width: 30%;
          height: 50%; */
        /* width: 30%; */
        /* height: auto; */
        /* position: absolute; */
        /* top: 50%; */
        /* right:50%; */
        /* -ms-transform: translateY(-50%); 
        transform: translateY(-50%); */
      }
      
      .home-text {
        /* border: 10px solid pink; */
        margin-bottom: 20px;
        padding: 0px 15px 0px 0px; 
        /* display: inline-block; */
        /* display: table-column; */
        /* justify-content: center; */
        /* float: center; */
      }
      
      .home-1{
        font-size: 100px;
        /* font-weight: 800; */
        font-weight: 600;
        
      }
      
      .home-2 {
        font-size: 23px;
        font-weight: 600;
        /* border: 1px solid red; */
        /* margin: 0;
        padding: 0; */
      }
      
      .home-3 {
        font-size: 17px;
        font-weight: lighter;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        /* margin-right: 15px; */
        /* border: 1px solid blue; */
        /* margin: 0;
        padding: 0; */
      }
      .home-text-align{
        margin-bottom: 0;
        padding-bottom: 5px;
      }
      .home-text-aligns{
        margin: 0;
        padding: 0;
      }
      
      .home-buttons-align {
        /* height: 100%; */
        width: 100%;
        /* width: fit-content; */
        /* border: 1px solid yellow; */
        display: flex;
        justify-content: center;
        /* align-items: center; */
        /* width: 40vw; */

      }
      
      .home-buttons {
        border-radius: 50%;
        height: 20.5vw;
        width: 20.5vw;
        margin-right: 2vw;
        /* height: 150px; */
        /* margin-right: 18px; */
        /* border: 10px solid black; */
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;

        /* font-size: 20px; */
        font-size: 4.45vw;
        font-weight: 600;
        
      }
      
      .home-buttons span:last-child{
        border: 0;
        padding-right: 0;
      }
      
      #yellow-btn {
        background-color: #EEA204;
        border: 1px solid #593E03;
      }
      
      #yellow-btn:hover {
        background-color: #F3F3F3;
        border: 1px solid black;
      }
      
      #red-btn {
        background-color: #FF3C24;
        border: 1px solid #63180F;
      }
      
      #red-btn:hover {
        background-color: #F3F3F3;
        border: 1px solid black;
      }
      
      #blue-btn {
        background-color: #81D8DA;
        border: 1px solid #367C7B;
      }
      
      #blue-btn:hover {
        background-color: #F3F3F3;
        border: 1px solid black;
      }
}
/* ----------------------------------------------------------------------------- */

/* @media (min-width:769px) and (max-width: 1410px) {
} */