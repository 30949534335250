.Resume-App {
    /* text-align: center; */
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Signika Negative', sans-serif;
    /* border: 10px solid green; */
    /* box-sizing: border-box; */
  }
  .Resume-container {
    background-color: #F3F3F3;  
    height: auto ; /* Subtract the height of the navigation bar from the container's height */
    width: 100vw;
    display: flex;
    flex-direction: column;
    /* border: 10px solid darkblue; */
    box-sizing: border-box;
  }
  
.table-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    /* padding: 150px 200px 100px 200px; */
    padding: 10.5vw 14vw 7vw 14vw;
    display: flex;

    /* border: 10px solid greenyellow; */
  }

  .table-title{
    font-size: 40px;
    font-weight: bold;
  }

/* .table tbody{
    border: 10px solid slateblue;
    box-sizing: border-box;
  padding: 150px 200px 100px 200px;
  display: flex;
} */


.table {
    border-collapse: collapse;
    font-size: 16px;
    width: 100%;
    /* box-sizing: border-box;
  padding: 150px 200px 100px 200px;
  display: flex; */
  }


  .table th, .table td {
    padding: 10px;
    /* border: 1px solid black; */
    vertical-align: top;
  }
  .table th {
    font-weight: bold;
  }

.add-border{
    border-bottom: 1px solid grey;
}

  .first-col{
    text-align: left;
    width: 50%;
    font-size: 30px;
  }

  .sec-col{
    width: 50%;
    /* flex-wrap: wrap;
    word-break: break-word; */
  }

  .dates{
    width: 10%;;
    text-align: right;
  }

  .info{
    text-align: left;
    word-break: break-word;
    width: 40%;
  }

.position{
    font-weight: bold;
    font-size: 19px;
}
.comp-name {
  font-style: italic;
}