.UC-App{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-align: center;
    box-sizing: border-box;
    /* border: 10px solid red; */
    width: 100%;
    height: 100%;
}

.UC-Container {

}